<template>
    <div class="container">
        <br>
        <div class="card">
            <div class="card-body">
                <div class="row" v-if="currentUser != null">
                    <div class=" col-sm-12 col-lg-12 text-center">
                        <span class="avatar brround avatar-xl" :style="'background-image: url(' +  $options.filters.PERFIL_IMG(photoURL) + ')'"></span>
                        <h3 class="mb-1 " v-show="currentUser.displayName != null">{{name}}</h3>
                        <p class="mb-4 ">{{email}}</p>
                        <hr>
                    </div>
                    <div class="row col-lg-8">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-label">Nome</label>
                                <input type="text" class="form-control" placeholder="Digite seu nome" v-model="name">
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="currentUser.providerData[0].providerId == 'password'">
                            <div class="form-group">
                                <label class="form-label">Senha</label>
                                <InputPassword v-model="oldPassword" />
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="currentUser.providerData[0].providerId == 'password'">
                            <div class="form-group">
                                <label class="form-label">Nova Senha</label>
                                <InputPassword v-model="newPassword" placeholder="Digite sua nova senha" />
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-label">Foto</label>
                                <!-- <b-form-file id="file-small" placeholder="Foto de Perfil" browse-text="Procurar" v-model="photo"  accept=".jpg, .png, .jpeg"></b-form-file> -->
                                <ImageUploader />
                            </div>
                        </div>
                        <div class="col-lg-12 text-right mb-4">
                            <button  type="submit" :class="'btn btn-primary ' +(sending ? 'btn-loading text-primary disabled' : '' )" @click="UpdateProfile">Atualizar</button>
                        </div>
                    </div>
                    
                    <PremiumCard />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app"
import { mapActions } from 'vuex'
import InputPassword from '@/components/widgets/InputPassword'
import PremiumCard from '@/components/widgets/PremiumCard'
import ImageUploader from '@/components/widgets/ImageUploader'

export default {
    components:{  InputPassword, PremiumCard, ImageUploader },
    data() {
        return {
            email: this.$store.state.user.CurrentUser.email,
            name: this.$store.state.user.CurrentUser.displayName,
            photoURL:this.$store.state.user.CurrentUser.photoURL,
            // photo:null,
            oldPassword:"",
            newPassword:"",
            sending:false,
        }
    },
    computed:{
        currentUser(){
            return this.$store.state.user.CurrentUser
        },
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
        photo:{
            get: function() {  return this.$store.state.user.photo },
            set: function(newValue) {this.$store.state.user.photo = newValue} 
        },
    },
    methods:{
        ...mapActions('global', ['TranslateErrorMessageFirebase']),
        async UpdateProfile(){
            this.sending = true
            let error = false
            
            if(this.currentUser.providerData[0].providerId == 'password') error = await this.UpdatePassword()
            if(!error) error = await this.UpdatePhoto()
            if(!error) error = await this.UpdateName()

            if(!error) this.$swal("Parabéns", "Seu dados foram atualizados com sucesso", "success")

            //NÃO CONSEGUI ATUALZIAR AS INFO DOS OUTROS COMPONENTES
            this.sending = false
        },
        UpdatePassword(){
            return new Promise(resolve => {
                if(this.newPassword == "" && this.oldPassword == ""){
                    resolve(false)
                    return
                } 
                var credential = firebase.auth.EmailAuthProvider.credential(
                    this.$store.state.user.CurrentUser.email, 
                    this.oldPassword
                );
                firebase.auth().currentUser.reauthenticateWithCredential(credential)
                    .then(() => {
                        this.currentUser.updatePassword(this.newPassword)
                            .then(() => {
                                firebase.analytics().logEvent('Update Password');
                                this.oldPassword = ""
                                this.newPassword = ""
                                resolve(false)
                            }).catch(async error => {
                                let response = await this.TranslateErrorMessageFirebase(error)
                                this.$swal(response.Title, response.ErrorMessage, response.Type)
                                resolve(true)
                            });
                    })
                    .catch(async error => {
                        let response = await this.TranslateErrorMessageFirebase(error)
                        this.$swal(response.Title, "Senha inválida", response.Type)
                        resolve(true)
                    });
                
            });
        },
        UpdateName(){
            return new Promise(resolve => {
                if(this.name == ''){
                    resolve(false)
                    return
                }
                this.currentUser.updateProfile({
                        displayName: this.name
                    }).then(() => {
                        resolve(false)
                        firebase.analytics().logEvent('Update Name');
                    }).catch(async error => {
                        let response = await this.TranslateErrorMessageFirebase(error)
                        this.$swal(response.Title, response.ErrorMessage, response.Type)
                        resolve(true)
                    })
            });
        },
        UpdatePhoto(){
            return new Promise(resolve => {
                if(this.photo == null){
                    resolve(false)
                    return
                }
                let splitPhotoName = this.photo.name.split(".");
                let extencion = splitPhotoName[splitPhotoName.length - 1]
                //IMG SUBIR NO STORAGE E PEGAR DE LÁ
                let imgPath = 'Profile/'+ this.currentUser.uid + '/' + this.currentUser.uid + '.' + extencion
                const storageRef = firebase.storage().ref(imgPath)
                storageRef.put(this.photo).then(() =>{
                    storageRef.getDownloadURL().then(response => {
                        this.currentUser.updateProfile({
                            photoURL: response
                        }).then(() => {
                            firebase.analytics().logEvent('Update Photo');
                            this.photoURL = response
                            document.getElementById('avatar').style.backgroundImage ='url('+response+')'
                            this.photo = null
                            resolve(false)
                        })
                        .catch(async error => {
                            let response = await this.TranslateErrorMessageFirebase(error)
                            this.$swal(response.Title, response.ErrorMessage, response.Type)
                            resolve(true)
                        })
                    })
                    .catch(async error => {
                        let response = await this.TranslateErrorMessageFirebase(error)
                        this.$swal(response.Title, response.ErrorMessage, response.Type)
                        resolve(true)
                    })
                })
                .catch(async error => {
                    let response = await this.TranslateErrorMessageFirebase(error)
                    this.$swal(response.Title, response.ErrorMessage, response.Type)
                    resolve(true)
                })
            });
        },
    },
    created(){
       firebase.analytics().logEvent('Access Profile');
    }
}
</script>

<style>

</style>