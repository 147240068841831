<template>
    <div>
        <image-uploader 
            :debug="0"
            :maxWidth="128"
            :quality="0.5"
            outputFormat="verbose"
            :preview=false
            :className="['fileinput btn']"
            :capture="false"
            accept="image/jpeg, image/png"
            @input="setImage"
        ></image-uploader>

    </div>
</template>

<script>
import ImageUploader from 'vue-image-upload-resize'
export default {
    components: {
        ImageUploader
    },
    computed:{
        photo:{
            get: function() {  return this.$store.state.user.photo },
            set: function(newValue) {this.$store.state.user.photo = newValue} 
        },
    },
    methods:{
        setImage(file){
            this.photo =  this.dataURLtoFile(file.dataUrl,file.info.name);
        },
        dataURLtoFile(dataurl, filename) {
 
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        }
       
    }
  // ...
}
</script>

<style>

</style>