<template>
    <div v-if="DataBase != null">
        <label class="custom-switch">
            <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" v-model="DataBase.settings[variable]"  @change="Save">
            <span class="custom-switch-indicator"></span>
            <span v-if="!$isMobile()" class="custom-switch-description">{{title}}</span>
            <small v-else class="custom-switch-description">{{title}}</small>
        </label>
    </div>
</template>

<script>

import firebase from "firebase/app"
export default {
    props: {
        variable: { type: String, required: true },
        title: { type: String }, 
    },
    computed:{
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
    },
    methods:{
        Save(){
            firebase.firestore().collection('account')
                .doc(this.$store.state.user.CurrentUser.uid)
                .set(this.DataBase)
            .catch((error) => {
                let params = new URLSearchParams()
                params.append('email', this.$store.state.user.CurrentUser.email )
                params.append('event',  JSON.stringify(error) )
                this.$api.post("/Errorlog/Set", params)
                console.log(error);
            })
        }
    }
}
</script>

<style>

</style>