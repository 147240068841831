<template>
  <div class="form-group text-left m-2">
    <label class="form-label">Código promocional</label>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        v-model="code"
        v-on:keyup.enter="Apply"
      />
      <div class="input-group-append">
        <button class="btn btn-primary" @click="Apply">Aplicar</button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  data() {
    return {
      code: "",
    };
  },
  computed: {
    DataBase() {
      return this.$store.state.portfolio.DataBase;
    },
    settings: {
      get: function () {
        return this.$store.state.portfolio.DataBase.settings;
      },
      set: function (newValue) {
        this.$store.state.portfolio.DataBase.settings = newValue;
      },
    },
  },
  mounted() {
    if (this.settings.premium.promotionalCode === undefined)
      this.settings.premium.promotionalCode = [];
  },
  methods: {
    Apply() {
      if (this.code == "") return;
      if (this.settings.premium.promotionalCode === undefined)
        this.settings.premium.promotionalCode = [];
      let Promotional = this.settings.premium.promotionalCode.filter(
        (row) => row.code == this.code
      );

      if (Promotional.length > 0) {
        this.$swal(
          "Opsss...",
          "Esse código já foi utilizado no dia " + Promotional[0].date,
          "warning"
        );
        return;
      }
      switch (this.code.toUpperCase()) {
        case "CH&INSIDE":
          this.SetPremium(6, 3, "2022/01/01");
          break;
        case "DNA@2023":
          this.SetPremium(12, 7, "2025/01/02");
          break;
        case "INSIDEAPP2022":
          this.SetPremium(6, 3, "2022/07/01");
          break;
        case "HOLDER#2021":
          this.SetPremium(1, 2, "2021/08/03");
          break;
        case "PODTECH#S01EP033":
          this.SetPremium(1, 2, "2022/03/01");
          break;
        case "#QUERO-SER-PREMIUM":
          this.SetPremium(1, 2, "2023/01/01");
          break;
        case "PIT&CLEAR":
          this.SetPremium(3, 6, "2022/02/01");
          break;
        default:
          this.$swal(
            "Atenção",
            "Por favor, digite um código promocional válido!",
            "error"
          );
          break;
      }
    },
    SetPremium(month, type, date) {
      if (new Date() > new Date(date)) {
        this.$swal(
          "Atenção",
          "Esse código promocional está expirado, por favor tente outro",
          "error"
        );
        return;
      }
      this.settings.premium.expirationDate = new Date(
        this.settings.premium.expirationDate
      );

      if (this.settings.premium.expirationDate < new Date())
        this.settings.premium.expirationDate = new Date();

      this.settings.premium.expirationDate = new Date(
        this.settings.premium.expirationDate.setMonth(
          this.settings.premium.expirationDate.getMonth() + month
        )
      );
      let dd = String(this.settings.premium.expirationDate.getDate()).padStart(
        2,
        "0"
      );
      let mm = String(
        this.settings.premium.expirationDate.getMonth() + 1
      ).padStart(2, "0"); //January is 0!
      let yyyy = this.settings.premium.expirationDate.getFullYear();
      this.settings.premium.expirationDate = yyyy + "-" + mm + "-" + dd;

      if (parseFloat(this.settings.premium.type) == 0)
        this.settings.premium.type = type;

      this.settings.premium.promotionalCode.push({
        code: this.code,
        date: new Date().toLocaleDateString(),
      });
      firebase.analytics().logEvent("Using promotional code: " + this.code);
      this.code = "";
      this.$swal("Parabéns!", "Seu código foi ativado com sucesso", "success");
      this.Save();
    },
    Save() {
      firebase
        .firestore()
        .collection("account")
        .doc(this.$store.state.user.CurrentUser.uid)
        .set(this.DataBase)
        .catch((error) => {
          let params = new URLSearchParams();
          params.append("email", this.$store.state.user.CurrentUser.email);
          params.append("event", JSON.stringify(error));
          this.$api.post("/Errorlog/Set", params);
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
