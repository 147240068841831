<template>
    <div v-if="DataBase != null" class="col-lg-4 text-center">
        <div v-if="DataBase.settings.premium.type == 0" class="card">
            <div class="card-body text-center text-azure">
                <div class="feature">
                    <h3 class="text-azure">Plano Básico</h3>
                    <p>Clique no botão para ver as vantagens de ser premium.</p>
                    <hr>
                    <div class="wrapper">
                        <router-link to="/pricing" class="btn btn-azure btn-block" >Sejá Premium</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="DataBase.settings.premium.type == 1" class="card">
            <div class="card-body text-center text-cyan">
                <div class="feature">
                    <h3>Plano Avançado</h3>
                    <p>Validade {{ new Date(DataBase.settings.premium.expirationDate).toLocaleDateString()}}</p>
                    <hr>
                    <div class="wrapper">
                        <router-link to="/pricing" class="btn btn-cyan btn-block" >Contratar mais</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="DataBase.settings.premium.type == 2" class="card">
            <div class="card-body text-center text-indigo">
                <div class="feature">
                    <h3>Plano Profissional</h3>
                    <p>Validade {{ new Date(DataBase.settings.premium.expirationDate).toLocaleDateString()}}</p>
                    <hr>
                    <div class="wrapper">
                        <router-link to="/pricing" class="btn btn-indigo btn-block" >Contratar mais</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="DataBase.settings.premium.type == 3" class="card">
            <div class="card-body text-center text-gray-dark">
                <div class="feature">
                    <h3>Plano Inside</h3>
                    <p>Validade {{ new Date(DataBase.settings.premium.expirationDate).toLocaleDateString()}}</p>
                    <hr>
                    <div class="wrapper">
                        <router-link to="/pricing" class="btn btn-gray-dark btn-block" >Contratar mais</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="DataBase.settings.premium.type == 4" class="card">
            <div class="card-body text-center  text-gray-dark">
                <div class="feature">
                    <h3>Plano Get Money</h3>
                    <p>Validade {{ new Date(DataBase.settings.premium.expirationDate).toLocaleDateString()}}</p>
                    <hr>
                    <div class="wrapper">
                        <router-link to="/pricing" class="btn btn-gray-dark btn-block" >Contratar mais</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="DataBase.settings.premium.type == 5" class="card">
            <div class="card-body text-center text-lime">
                <div class="feature">
                    <h3>Plano Ponto de Partida</h3>
                    <p>Validade {{ new Date(DataBase.settings.premium.expirationDate).toLocaleDateString()}}</p>
                    <hr>
                    <div class="wrapper">
                        <router-link to="/pricing" class="btn btn-lime btn-block" >Contratar mais</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="DataBase.settings.premium.type == 6" class="card">
            <div class="card-body text-center text-lime">
                <div class="feature">
                    <h3>Plano Clear</h3>
                    <p>Validade {{ new Date(DataBase.settings.premium.expirationDate).toLocaleDateString()}}</p>
                    <hr>
                    <div class="wrapper">
                        <router-link to="/pricing" class="btn btn-lime btn-block" >Contratar mais</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="DataBase.settings.premium.type == 7" class="card">
            <div class="card-body text-center text-blue">
                <div class="feature">
                    <h3>Plano DNA (Dividendos No Automático)</h3>
                    <p>Validade {{ new Date(DataBase.settings.premium.expirationDate).toLocaleDateString()}}</p>
                    <hr>
                    <div class="wrapper">
                        <router-link to="/pricing" class="btn btn-blue btn-block" >Contratar mais</router-link>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <PromotionalCode />
    </div>
</template>

<script>
import PromotionalCode from "@/components/widgets/PromotionalCode"
export default {
    components:{  PromotionalCode },
    computed:{
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
    },
}
</script>

<style>

</style>