<template>
  <div class="container">
    <br />
    <h3 class="text-center">Seja Premium - Escolha seu plano</h3>
    <hr />
    <div v-if="loading" class="row text-center m-1 mb-4 mt-4 border bg-white">
      <h4 class="col-12"></h4>
      <font-awesome-icon
        icon="spinner"
        class="fa-spin header-loading col-12 text-green mb-4"
        size="4x"
      />
    </div>
    <div v-if="!loading" class="row">
      <div class="col-12 form-group">
        <label class="form-label">Cupom de desconto</label>
        <input
          type="text"
          ref="code"
          class="form-control"
          v-on:keyup="desc()"
          name="example-text-input"
          v-model="cupom"
        />
      </div>
      <div class="col-sm-12 col-lg-4">
        <div v-if="this.professional.yearly != 45" class="card">
          <div class="text-center pricing pricing1">
            <div class="card-category bg-azure">Acesso Básico</div>
            <ul class="list-unstyled leading-loose">
              <li><b>R$ 0,00 </b>/ Mensal</li>
              <li><b>R$ 0,00 </b>/ Anual</li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Acesso a plataforma
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Portfólio consolidado
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Plataforma sem anúncios
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Cadastro ilimitado de ativos
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Acesso ao suporte
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Escolher quais ativos não aportar
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Utilizar dólar como moeda principal
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Cadastro de preço teto
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Rebalanceamento por categoria
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" /> Até
                três carteiras
              </li>
            </ul>
            <div class="text-center ml-4 mr-4 mt-4 mb-4">
              <router-link to="/home" class="btn btn-azure btn-block"
                >Acessar</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.professional.yearly != 45" class="col-sm-12 col-lg-4">
        <div class="card">
          <div class="text-center pricing pricing1">
            <div class="card-category bg-cyan">
              Acesso {{ advancing.title }}
            </div>
            <ul class="list-unstyled leading-loose">
              <div v-if="!Promotion">
                <li>
                  <b>{{ advancing.monthly | BRL }} </b>/ Mensal
                </li>
                <li>
                  De
                  <span class="through">{{
                    (advancing.monthly * 12) | BRL
                  }}</span>
                  por <b>{{ advancing.yearly | BRL }}</b> / Anual
                </li>
              </div>
              <div v-else>
                <li>
                  <b>{{ 4.17 | BRL }} </b>/ Mensal
                </li>
                <li>
                  De <span class="through">{{ (4.17 * 12) | BRL }}</span> por
                  <b>{{ 40 | BRL }}</b> / Anual
                </li>
              </div>

              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Acesso a plataforma
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Portfólio consolidado
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Plataforma sem anúncios
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Cadastro ilimitado de ativos
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Acesso ao suporte
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Escolher quais ativos não aportar
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Utilizar dólar como moeda principal
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Cadastro de preço teto
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" />
                Rebalanceamento por categoria
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="times" class="text-danger mr-1" /> Até
                três carteiras
              </li>
            </ul>
            <div class="row text-center m-1 mb-4 mt-4">
              <div class="col-12 mt-2">
                <a
                  :href="linkAdvancingMonthly"
                  :target="linkAdvancingMonthly == '#' ? '' : '_blank'"
                  class="btn btn-cyan btn-block"
                  @click="Buy(advancing.title, 1, advancing.monthly)"
                  >Comprar Mensal</a
                >
              </div>
              <div class="col-12 mt-2">
                <a
                  :href="linkAdvancingYearly"
                  :target="linkAdvancingYearly == '#' ? '' : '_blank'"
                  class="btn btn-cyan btn-block"
                  @click="Buy(advancing.title, 12, advancing.yearly)"
                  >Comprar Anual</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-4">
        <div class="card">
          <div class="text-center pricing pricing1">
            <div class="card-category bg-indigo">
              Acesso {{ professional.title }}
            </div>
            <ul class="list-unstyled leading-loose">
              <div v-if="!Promotion">
                <li>
                  <b>{{ professional.monthly | BRL }} </b>/ Mensal
                </li>
                <li>
                  De
                  <span class="through">{{
                    (professional.monthly * 12) | BRL
                  }}</span>
                  por <b>{{ professional.yearly | BRL }}</b> / Anual
                </li>
              </div>
              <div v-else>
                <li>
                  <b>{{ 7.5 | BRL }} </b>/ Mensal
                </li>
                <li>
                  De <span class="through">{{ (7.5 * 12) | BRL }}</span> por
                  <b>{{ 60 | BRL }}</b> / Anual
                </li>
              </div>
              <!-- <li v-if="new Date() < new Date(datePromocion)">De <span class="through">{{professional.monthly * 12 | BRL}}</span> por <b>{{professional.yearly | BRL}}</b> / Anual</li> -->
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Acesso a plataforma
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Portfólio consolidado
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Plataforma sem anúncios
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Cadastro ilimitado de ativos
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Acesso ao suporte
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Escolher quais ativos não aportar
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Utilizar dólar como moeda principal
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Cadastro de preço teto
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" />
                Rebalanceamento por categoria
              </li>
              <li class="text-left pl-3 pr-3">
                <font-awesome-icon icon="check" class="text-success mr-1" /> Até
                três carteiras
              </li>
            </ul>
            <div class="row text-center m-1 mb-4 mt-4">
              <div class="col-12 mt-2">
                <a
                  :href="linkProfessionalMonthly"
                  :target="linkProfessionalMonthly == '#' ? '' : '_blank'"
                  class="btn btn-indigo btn-block"
                  @click="Buy(professional.title, 1, professional.monthly)"
                  >Comprar Mensal</a
                >
              </div>
              <div class="col-12 mt-2">
                <a
                  :href="linkProfessionalYearly"
                  :target="linkProfessionalYearly == '#' ? '' : '_blank'"
                  class="btn btn-indigo btn-block"
                  @click="Buy(professional.title, 1, professional.monthly)"
                  >Comprar Anual</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-1 mb-4 mt-4">
      <div class="col-12 text-center">
        <img
          :style="!$isMobile() ? 'max-width:450px;' : ''"
          src="@/assets/img/SeloMercadoPago.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script src="https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js"></script>
<script>
import firebase from "firebase/app";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      cupom: "",
      cupomOld: "",
      loading: false,
      datePromocion: "2021-07-31",
      advancing: {
        title: "Avançado",
        monthly: 9.99,
        yearly: 49.99,
      },
      professional: {
        title: "Profissional",
        monthly: 15.99,
        yearly: 89.99,
      },
      linkProfessionalMonthly: "#",
      linkProfessionalYearly: "#",
      linkAdvancingMonthly: "#",
      linkAdvancingYearly: "#",
      Promotion: false,
    };
  },
  mounted() {
    firebase.analytics().logEvent("View page Pricing");
    this.loading = true;
    this.BlackWeek();
    this.Buy(
      this.advancing.title,
      1,
      this.Promotion ? 4.16 : this.advancing.monthly,
      "linkAdvancingMonthly"
    );
    this.Buy(
      this.advancing.title,
      12,
      this.Promotion ? 40.0 : this.advancing.yearly,
      "linkAdvancingYearly"
    );
    this.Buy(
      this.professional.title,
      1,
      this.Promotion ? 7.5 : this.professional.monthly,
      "linkProfessionalMonthly"
    );
    this.Buy(
      this.professional.title,
      12,
      this.Promotion ? 60.0 : this.professional.yearly,
      "linkProfessionalYearly"
    );
  },
  computed: {
    currentUser() {
      return this.$store.state.user.CurrentUser;
    },
    DataBase() {
      return this.$store.state.portfolio.DataBase;
    },
  },
  methods: {
    desc() {
      if (
        Date.parse(new Date()) >= Date.parse(new Date("2023-10-01")) &&
        Date.parse(new Date()) < Date.parse(new Date("2023-11-02"))
      ) {
        if (this.cupom.toUpperCase() == "PIT-50%-OFF") {
          this.loading = true;
          this.professional = {
            title: "Profissional",
            monthly: 8.0,
            yearly: 45.0,
          };
          this.Buy(
            this.professional.title,
            1,
            this.professional.monthly,
            "linkProfessionalMonthly"
          );
          this.Buy(
            this.professional.title,
            12,
            this.professional.yearly,
            "linkProfessionalYearly"
          );
        } else if (this.cupomOld.toUpperCase() == "PIT-50%-OFF") {
          this.loading = true;
          this.professional = {
            title: "Profissional",
            monthly: 15.99,
            yearly: 89.99,
          };
          this.Buy(
            this.professional.title,
            1,
            this.professional.monthly,
            "linkProfessionalMonthly"
          );
          this.Buy(
            this.professional.title,
            12,
            this.professional.yearly,
            "linkProfessionalYearly"
          );
        }
        this.cupomOld = this.cupom;
      }
    },
    ...mapActions("global", ["GetAccessToken"]),
    BlackWeek() {
      return new Promise((resolve) => {
        if (
          Date.parse(new Date()) >= Date.parse(new Date("2023-11-20")) &&
          Date.parse(new Date()) < Date.parse(new Date("2023-12-02"))
        )
          this.Promotion = true;
        else this.Promotion = false;
        resolve(true);
      });
    },
    async Buy(title, month, price, link = null) {
      if (
        title == this.advancing.title &&
        this.DataBase.settings.premium.type >= 2
      ) {
        const Msg = `Não é possível contratar um plano inferior.
                             O seu plano atual é o ${this.professional.title}.
                             Esse procedimento será liberado, quando seu plano expirar, no dia ${new Date(
                               this.DataBase.settings.premium.expirationDate
                             ).toLocaleDateString()}.`;

        if (link == null) this.$swal("Opsss...", Msg, "warning");
        return;
      }
      if (link == null) return;
      // this.loading = true
      let newURL =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      let NewTitle = "Premium " + title + (month == 12 ? " Anual" : " Mensal");
      let Values = [NewTitle, month, price, newURL, this.currentUser.email];
      const AccessToken = await this.GetAccessToken(Values);

      let params = new URLSearchParams();
      params.append("title", NewTitle);
      params.append("month", month);
      params.append("price", price);
      params.append("url", newURL);
      params.append("email", this.currentUser.email);
      params.append("AccessToken", AccessToken);

      this.$api
        .post("/Mercadopago/CreatePayment/" + this.currentUser.uid, params)
        .then(async (response) => {
          this.loading = false;
          if (!response.data.error) {
            // firebase.analytics().logEvent('Open page - MercadoPago');
            this[link] = response.data.redirect;
          } else {
            firebase.analytics().logEvent("Error in buy");
            //this.$swal(response.data.message, '', 'error')
            this[link] = "#";
          }
        });
    },
  },
};
</script>

<style>
.through {
  text-decoration: line-through;
  font-weight: bold;
}
</style>
