<template>
  <div class="container">
    <br />
    <div class="card">
      <div class="card-header">
        <h3 class="card-title col-8">Histórico de pagamento</h3>
      </div>
      <b-table
        hover
        responsive
        :items="items"
        :fields="fields"
        :busy="show"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <h3>Carregando...</h3>
          </div>
        </template>
        <template #cell(date_created)="data">
          {{ new Date(data.item.date_created).toLocaleDateString("pt-br") }}
        </template>
        <template #cell(date_approved)="data">
          {{ new Date(data.item.date_approved).toLocaleDateString("pt-br") }}
        </template>
        <template #cell(transaction_amount)="data">
          {{ data.item.transaction_amount | BRL }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      sortBy: "date_created",
      sortDesc: true,
      items: [],
      fields: [
        {
          key: "date_created",
          label: "Data da Compra",
          sortable: true,
          class: "text-center",
        },
        {
          key: "date_approved",
          label: "Data da Aprovação",
          sortable: true,
          class: "text-center",
        },
        {
          key: "description",
          label: "Plano",
          sortable: true,
          class: "text-center",
        },
        {
          key: "site",
          label: "Site da compra",
          sortable: true,
          class: "text-center",
        },
        {
          key: "transaction_amount",
          label: "Valor",
          sortable: true,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    DataBase() {
      return this.$store.state.portfolio.DataBase;
    },
  },
  mounted() {
    let params = new URLSearchParams();
    params.append("email", this.$store.state.user.CurrentUser.email);
    params.append("uid", this.$store.state.user.CurrentUser.uid);
    this.$api.post("/buys/list/", params).then((reponse) => {
      if (reponse.data.error == false) {
        this.items = reponse.data.value;
      }

      if (this.DataBase == null) return;
      if (this.DataBase.settings.premium.promotionalCode === undefined) {
        this.show = false;
        return;
      }
      for (
        let index = 0;
        index < this.DataBase.settings.premium.promotionalCode.length;
        index++
      ) {
        const element = this.DataBase.settings.premium.promotionalCode[index];

        this.items.push({
          date_created: this.formatDate(element.date),
          date_approved: this.formatDate(element.date),
          transaction_amount: 0,
          site: "-",
          description: "Código promocional", // + ': ' + element.code,
        });
      }
      this.show = false;
      // console.log(reponse);
    });
  },
  methods: {
    formatDate(date) {
      var d = date.split("/");
      let day = d[0];
      let month = d[1];
      let year = d[2];

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-") + "T03:10:10.000-04:00";
    },
  },
};
</script>

<style></style>
