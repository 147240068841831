<template>
  <div class="container">
    <br />
    <div class="card" v-if="DataBase != null">
      <div class="card-header">
        <h3 class="card-title">Configurações</h3>
      </div>
      <div class="card-body">
        <h4>Rebalanceamento</h4>
        <CustomSwitchSettings
          variable="usesDecimal"
          title="Casas decimais em Investimentos internacionais"
        />
        <!-- <CustomSwitchSettings variable="skipGoal" title="Permissão para exceder porcentagem objetiva"  /> -->
        <!-- <CustomSwitchSettings variable="showAllCoins" title="Mostrar valores em Reais e Dólar"  /> -->
        <!-- <CustomSwitchSettings v-show="!DataBase.settings.showAllCoins" variable="usdCoin" title="Usar como padrão o Dólar Americano"  />  -->
        <hr />
        <div
          v-if="
            DataBase.portfolios != null && DataBase.portfolios !== undefined
          "
        >
          <h4>Minhas Carteiras</h4>
          <label class="custom-switch pb-2">
            <input
              type="checkbox"
              name="custom-switch-checkbox"
              class="custom-switch-input"
              @change="ChangePortfolio(0)"
              v-model="DataBase.portfolios[0].select"
            />
            <span class="custom-switch-indicator"></span>
            <span class="custom-switch-description pl-4">
              <input
                type="text"
                class="form-control"
                name="example-text-input"
                placeholder="Carteira 01"
                v-model="DataBase.portfolios[0].name"
              />
            </span>
          </label>
          <br />
          <label class="custom-switch pb-2">
            <input
              type="checkbox"
              name="custom-switch-checkbox"
              class="custom-switch-input"
              @change="ChangePortfolio(1)"
              v-model="DataBase.portfolios[1].select"
            />
            <span class="custom-switch-indicator"></span>
            <span class="custom-switch-description pl-4">
              <input
                type="text"
                class="form-control"
                name="example-text-input"
                placeholder="Carteira 02"
                v-model="DataBase.portfolios[1].name"
              />
            </span>
          </label>
          <br />
          <label class="custom-switch pb-2">
            <input
              type="checkbox"
              name="custom-switch-checkbox"
              class="custom-switch-input"
              @change="ChangePortfolio(2)"
              v-model="DataBase.portfolios[2].select"
            />
            <span class="custom-switch-indicator"></span>
            <span class="custom-switch-description pl-4">
              <input
                type="text"
                class="form-control"
                name="example-text-input"
                placeholder="Carteira 03"
                v-model="DataBase.portfolios[2].name"
              />
            </span>
          </label>
          <br />
          <br />
          <p>
            <button class="btn btn-pill btn-danger" @click="ClearPortFolio">
              Limpar Carteira selecionada
            </button>
          </p>
        </div>

        <!-- Somente o usuario suporte tem acesso a essa informação -->
        <span
          v-if="
            this.$store.state.user.CurrentUser.uid ==
              '52XPZtO5s5WYP6j8I6bUwNJcK9a2' &&
            this.$store.state.user.CurrentUser.email ==
              'contato@carteiraholder.com.br'
          "
        >
          <hr />
          <div class="row">
            <div class="col-lg-4 mt-2">
              <input
                type="text"
                class="form-control"
                placeholder="UID"
                v-model="uid"
              />
            </div>
            <div class="col-lg-4 mt-2">
              <input
                type="text"
                class="form-control"
                placeholder="E-mail"
                v-model="email"
              />
            </div>
            <div class="col-lg-4 mt-2">
              <input
                type="password"
                class="form-control"
                placeholder="Senha Token"
                v-model="key"
              />
            </div>
            <div class="col-lg-4 mt-2">
              <p>
                <button class="btn btn-pill btn-cyan" @click="RecoverBackup">
                  Recuperar Backup
                </button>
              </p>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import Swal from "sweetalert2";
import CustomSwitchSettings from "@/components/widgets/CustomSwitchSettings";
import { mapActions } from "vuex";
export default {
  components: { CustomSwitchSettings },
  data() {
    return {
      uid: "",
      email: "",
      key: "",
      url: window.location.host,
    };
  },
  computed: {
    DataBase: {
      get: function () {
        return this.$store.state.portfolio.DataBase;
      },
      set: function (newValue) {
        this.$store.state.portfolio.DataBase = newValue;
      },
    },
    AllClassPortfolio() {
      return this.$store.state.global.AllClassPortfolio;
    },
    PremiumType() {
      return this.$store.state.portfolio.DataBase.settings.premium.type;
    },
  },
  created() {
    if (this.DataBase == null) return;
    if (this.DataBase.portfolios === undefined) {
      this.DataBase.portfolios = [
        {
          name: "Carteira Atual",
          select: true,
          stocks: this.DataBase.portfolio.stocks,
          class: this.DataBase.portfolio.class,
        },
        {
          name: "Carteira 02",
          select: false,
          stocks: [],
          class: this.allClass(),
        },
        {
          name: "Carteira 03",
          select: false,
          stocks: [],
          class: this.allClass(),
        },
      ];
    }
  },
  methods: {
    ...mapActions("global", ["MsgNotPermission"]),
    RecoverBackup() {
      console.log(window.location.host);

      if (window.location.host != "localhost:8080") return;

      let params = new URLSearchParams();
      params.append("uid", this.uid);
      params.append("email", this.email);
      params.append("key", this.key);

      this.$api.post("/Backup/Get", params).then((Response) => {
        if (!Response.data.error) this.DataBase = Response.data.value;
        if (Response.data.error) alert(Response.data.message);
      });
    },
    allClass() {
      return [
        { code: "acoes", isBuy: true, objectivePercentage: "0" },
        { code: "fiis", isBuy: true, objectivePercentage: "0" },
        { code: "rf", isBuy: true, objectivePercentage: "0" },
        { code: "etfs", isBuy: true, objectivePercentage: "0" },
        { code: "bdrs", isBuy: true, objectivePercentage: "0" },
        { code: "stocks", isBuy: true, objectivePercentage: "0" },
        { code: "reits", isBuy: true, objectivePercentage: "0" },
        { code: "etf_eua", isBuy: true, objectivePercentage: "0" },
        { code: "adrs", isBuy: true, objectivePercentage: "0" },
        { code: "criptomoedas", isBuy: true, objectivePercentage: "0" },
      ];
    },
    ToastSuccess(icon, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: icon,
        title: title,
      });
    },
    ChangePortfolio(index) {
      if (this.PremiumType < 2) {
        this.MsgNotPermission(
          'Essa funcionalidade só está disponível para usuários do nível <b>profissional</b><br>Clique aqui e <a href="pricing"> <b> Seja um Premium </b></a> profissional'
        ).then(() => {
          this.DataBase.portfolios[index].select =
            !this.DataBase.portfolios[index].select;
        });
        return;
      }
      if (
        !this.DataBase.portfolios[0].select &&
        !this.DataBase.portfolios[1].select &&
        !this.DataBase.portfolios[2].select
      ) {
        setTimeout(() => {
          this.ToastSuccess(
            "error",
            "Não é possível desmarcar essa carteira, para trocar de carteira selecione uma desmarcada."
          );
          this.DataBase.portfolios[index].select = true;
        }, 250);
        return;
      }
      if (this.DataBase.portfolios[0].select && index != 0) {
        this.DataBase.portfolios[0].stocks = this.DataBase.portfolio.stocks;
        this.DataBase.portfolios[0].class = this.DataBase.portfolio.class;

        this.DataBase.portfolios[0].select = false;
      }
      if (this.DataBase.portfolios[1].select && index != 1) {
        this.DataBase.portfolios[1].stocks = this.DataBase.portfolio.stocks;
        this.DataBase.portfolios[1].class = this.DataBase.portfolio.class;

        this.DataBase.portfolios[1].select = false;
      }
      if (this.DataBase.portfolios[2].select && index != 2) {
        this.DataBase.portfolios[2].stocks = this.DataBase.portfolio.stocks;
        this.DataBase.portfolios[2].class = this.DataBase.portfolio.class;

        this.DataBase.portfolios[2].select = false;
      }

      this.DataBase.portfolios[index].select = true;
      this.DataBase.portfolio.stocks = this.DataBase.portfolios[index].stocks;
      this.DataBase.portfolio.class = this.DataBase.portfolios[index].class;

      this.DataBase.settings.lastConsolidated = 1023888000000;
      this.Save();
    },
    ClearPortFolio() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success mr-2",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Tem certeza?",
          html: "Você está prestes a limpar sua carteira.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.state.portfolio.DataBase.portfolio.stocks = [];

            for (
              let index = 0;
              index <
              this.$store.state.portfolio.DataBase.portfolio.class.length;
              index++
            ) {
              const element =
                this.$store.state.portfolio.DataBase.portfolio.class[index];
              element.isBuy = true;
              element.objectivePercentage = 0;
            }

            this.Save();

            Swal.fire(
              "Limpa!",
              "Sua carteira foi limpa com sucesso.",
              "success"
            );
          }
        });
    },
    Save() {
      firebase
        .firestore()
        .collection("account")
        .doc(this.$store.state.user.CurrentUser.uid)
        .set(this.DataBase)
        .catch((error) => {
          let params = new URLSearchParams();
          params.append("email", this.$store.state.user.CurrentUser.email);
          params.append("event", JSON.stringify(error));
          this.$api.post("/Errorlog/Set", params);
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
